import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import toast from "react-hot-toast";
import apiConfigs from "src/apiconfig/apiconfig";
import { getDataHandlerWithToken } from "src/apiconfig/service";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.sessionStorage.getItem("token")
    ? window.sessionStorage.getItem("token")
    : token;
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // const getProfileData = async () => {
  //   try {
  //     const res = await getDataHandlerWithToken("getProfile");
  //     if (res.result) {
  //       setUserData(res.result);
  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };
  const getProfileData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfigs.getProfile,
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (res?.data?.responseCode === 200) {
        //toast.success(res?.data?.responseMessage);
        setUserData(res?.data?.result);
        setIsLoading(false);
      }
    } catch (error) {
      // toast.error(error?.res?.data?.responseMessage);
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("token")) getProfileData();
  }, [sessionStorage.getItem("token")]);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);
  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    isLoading,
    timeLeft,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    setIsLogin,
    getProfileData: () => {
      getProfileData();
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
