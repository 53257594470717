// const url = "https://node.barakskyarbitrage.com/api/v1/admin"; // staging
 const url = "https://nodepune-cexjoseph.mobiloitte.io/api/v1/admin"; // staging
 const url2 ="https://nodepune-cexjoseph.mobiloitte.io/api/v1";
//const url2 ="http://172.16.2.14:1979/api/v1";
 //const url = "http://172.16.0.128:1979/api/v1/admin"; // staging
// const url = "http://172.16.1.217:1984"; // local
// const url = "https://node.mbaacademy.asia"; // live
 //const url = "http://172.16.2.14:1979/api/v1/admin"; //suraj local

 //export const url = "http://172.16.6.80:1979/api/v1/admin" //jayesh local url

const ApiConfig = {
  login: `${url}/login`,
  // loginadmin: `${url}/api/v1/admin/login`,
  // signup: `${url}/api/v1/user/register`,
  // verify: `${url}/api/v1/user/verify`,
  verifyOTP: `${url}/verifyOTP`,
  // forgot: `${url}/api/v1/user/forgot`,
  forgotPassword: `${url}/forgotPassword`,
  // changePassword: `${url}/api/v1/user/changePassword`,
  resetPassword: `${url}/resetPassword`,
  changePasswordAdmin: `${url}/changePassword`,
  // resend: `${url}/api/v1/user/resend`,
  resendOTP: `${url}/resendOTP`,
  dashboard: `${url}/adminDashboard`,
  // profile: `${url}/api/v1/user/profile`,
   getProfile: `${url}/getProfile`,
   editProfile: `${url}/editProfile`,
  // adminEditProfile: `${url}/api/v1/admin/editProfile`,
  // uploadImage: `${url}/api/v1/upload/uploadImage`,

  // kyclist: `${url}/api/v1/admin/kyc/list`,
  // viewKyc: `${url}/api/v1/admin/kyc/view`,
  // approveOrReject: `${url}/api/v1/admin/kyc/approveOrReject`,
  // listUser: `${url}/api/v1/admin/listUser`,
  // viewUser: `${url}/api/v1/admin/viewUser`,
  // createPlan: `${url}/api/v1/plan/create`,
  // editPlan: `${url}/api/v1/plan/edit`,
  // viewPlan: `${url}/api/v1/plan/view`,
  // Planlist: `${url}/api/v1/plan/list`,
  // blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  // delete: `${url}/api/v1/plan/delete/`,

  listPrediction: `${url}/listPrediction`,
  runScript: `${url}/runScript`,
  stopScript:`${url}/stopScript`,
  addCoins:`${url}/addCoins`,
  viewCoins:`${url}/viewCoins`,
  deleteParticularCoin:`${url}/deleteParticularCoin`,
  coinStatus:`${url}/coinStatus`,
  coinPairData:`${url}/coinPairData`,
  addThreshold:`${url}/addThreshold`,
  updateThreshold:`${url}/updateThreshold`,
  thresholdList:`${url}/thresholdList`,
  listUsers:`${url}/listUsers`,
  viewThreshold:`${url}/viewThreshold`,
  getUserSubscriptions:`${url}/getUserSubscriptions`,
  viewStaticContent:`${url}/viewStaticContent`,
  viewInitialAmount:`${url}/viewInitialAmount`,
  staticContentList:`${url2}/static/staticContentList`,
  deleteStaticContent:`${url2}/static/deleteStaticContent`,
  viewStaticContent:`${url2}/static/viewStaticContent`,
  editStaticContent:`${url2}/static/editStaticContent`,
  create:`${url2}/plan/create`,
  deleteFAQ:`${url2}/static/deleteFAQ`,
  faqList:`${url2}/static/faqList`,
  editFAQ:`${url2}/static/editFAQ`,
  addFAQ:`${url2}/static/addFAQ`,
  addStaticContent:`${url2}/static/addStaticContent`,
  viewFAQ:`${url2}/static/viewFAQ`,
  getBotStatus:`${url}/getBotStatus`,
  list:`${url2}/plan/list`,
  view:`${url2}/plan/view`,
  edit:`${url2}/plan/edit`,
  delete:`${url2}/plan/delete`,
  activateDeactivatePlan:`${url}/activateDeactivatePlan`,
  blockUnblockUser:`${url}/blockUnblockUser`,
  listWithdrawHistory:`${url}/listWithdrawHistory`,
  listDepositHistory:`${url}/listDepositHistory`,
  viewWalletDetails:`${url}/viewWalletDetails`,
  viewUser:`${url}/viewUser`,
  updateWalletAddress:`${url}/updateWalletAddress`,
};
export default ApiConfig;
