import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    exact: true,
    path: "/kyc-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/KycList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
    ),
  },

  {
    exact: true,
    path: "/view-kyc",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/ViewKyc")
    ),
  },
  {
    exact: true,
    path: "/withdrawal-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WithdrawalManagement/WithdrawalList")
    ),
  },
  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserList")
    ),
  },
  {
    exact: true,
    path: "/edit-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/EditContent")
    ),
  },
  {
    exact: true,
    path: "/view-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/ViewContent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/content-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/ContentList")
    ),
  },
  {
    exact: true,
    path: "/user-view",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../src/views/pages/Dashboard/ChangePassword/ChangePassword")
    ),
  },

  {
    exact: true,
    path: "/Enternew-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../src/views/pages/Dashboard/ChangePassword/NewPassword")
    ),
  },
  {
    exact: true,
    path: "/change-otp",
    layout: DashboardLayout,
    component: lazy(() =>
      import("../src/views/pages/Dashboard/ChangePassword/ChangePasswordOtp")
    ),
  },

  {
    exact: true,
    path: "/profile",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/view-subscription",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/ViewSubscription")
    ),
  },
  {
    exact: true,
    path: "/view-strategy",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StategyManagement/StrategyView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-subscription",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/AddSubscibe")
    ),
  },
  {
    exact: true,
    path: "/strategy-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StategyManagement/strategylist")
    ),
  },
  {
    exact: true,
    path: "/prediction-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PredictionManagement/PredictionList")
    ),
  },
  {
    exact: true,
    path: "/prediction-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PredictionManagement/PredictionView")
    ),
  },
  {
    exact: true,
    path: "/batch-view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/WithdrawalManagement/BatchView")),
  },
  {
    exact: true,
    path: "/user-view",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/ViewUser")),
  },
  {
    exact: true,
    path: "/strategy-view",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/StategyManagement/viewstrategy")),
  },
  {
    exact: true,
    path: "/bot-setting-arbitrage",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BotSetting/Botsetting")),
  },
  {
    exact: true,
    path: "/update-currency",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BotSetting/Update")),
  },
  {
    exact: true,
    path: "/add-plan",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscribtion/PlanAdd.js")),
  },
  {
    exact: true,
    path: "/faq-management",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/FaqManagement/Faqlist.js")),
  },
  {
    exact: true,
    path: "/view-faq",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/FaqManagement/ViewFaq.js")),
  },
  {
    exact: true,
    path: "/add-faq",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/FaqManagement/AddFaq.js")),
  },
  {
    exact: true,
    path: "/edit-faq",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/FaqManagement/EditFaq.js")),
  },
  {
    exact: true,
    path: "/view-threshold",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BotSetting/ViewThreshhold")),
  },
  {
    exact: true,
    path: "/view-plan",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscribtion/ViewPlan")),
  },
  {
    exact: true,
    path: "/edit-plan",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscribtion/EditPlan")),
  },
  
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
