import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export default function AuthGuard(props) {
  const { children, userLoggedIn } = props; // Destructure userLoggedIn directly
  const auth = useContext(AuthContext);

  console.log("userLoggedIn in AuthGuard:", userLoggedIn);
  console.log("auth.userLoggedIn:", auth.userLoggedIn);

  if (!auth.userLoggedIn) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
