import React, { useContext } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    // minHeight: "calc(100vh - 415px)",
  },

  root: {
    background: "url('images/lightbackground.png')",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  root1: {
    // backgroundColor: "#000",
    overflow: "hidden",
    position: "relative",
    background: "url('images/darkbackground.png')",
    height: "100vh",
  },
  MainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer1: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <div className={classes.contentContainer1}>
        <Box style={{ padding: "20px" }}>
          <Box style={{ cursor: "pointer" }} onClick={() => history.push("/")}>
            <img src="images/logo.svg" />
          </Box>
        </Box>
        <div className={classes.MainLayout}>{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
